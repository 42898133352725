import axios from 'axios'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export const sendMail = (data) => {
  const params = new URLSearchParams();
  params.append('email', data.email);
  params.append('name', data.name);
  params.append('telephone', data.telephone);
  params.append('societe', data.societe);
  params.append('beginDate', data.realBeginDate);
  params.append('endDate', data.realEndDate);
  params.append('question1', data.question1);
  params.append('question2', data.question2);
  params.append('question3', data.question3);
  params.append('question4', data.question4);
  params.append('question5', data.question5);
  params.append('question6', data.question6);
  params.append('question7', data.question7);
  params.append('question8', data.question8);
  params.append('question9', data.question9);
  params.append('question10', data.question10);
  params.append('question11', data.question11);
  params.append('question12', data.question12);
  params.append('question13', data.question13);
  params.append('question14', data.question14);
  params.append('question15', data.question15);
  params.append('question16', data.question16);
  params.append('question17', data.question17);
  params.append('question18', data.question18);
  params.append('question19', data.question19);
  params.append('comment', data.comment);
  params.append('suggestion', data.suggestion);

    axios.post('/mail.php', params)
      .then((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      });
}