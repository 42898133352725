import header from './../img/header.jpeg';
import footerLogo from './../img/footerLogo.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Thanks.css';
import React from 'react';

const ThanksScreen = () => {

    return (
        <div className="ThanksContainer">
            <img className="ThanksHeaderImg" src={header} alt="header" />
            <div className="ThanksTitle">Votre avis sur cet événement Impressive Tunisia member of Kyranis Travel</div>
            <div className="ThanksForm">
                <div className="ThanksContent">
                </div>
                <div className="ThanksBox">

                    <div className="ThanksOverlay">
                        <img className="ImgTopLeft" src={imgTopLeft} alt="imgTopLeft" />
                        <img className="ImgBottomRight" src={imgBottomRight} alt="ImgBottomRight" />
                    </div>
                    <div className="ThanksDescriptionTitle">Merci de votre temps</div>
                    <div className="ThanksDescriptionContent">Avec nos amitiés,<br/>L’équipe Impressive Tunisia</div>
                </div>
                <div style={{height: '50px'}}></div>
                <div className="ThanksDescriptionFooter">Ce formulaire a été créé dans Impressive Tunisia.<br/>Signaler un cas d'utilisation abusive</div>
            </div>
            <div className="ThanksFooterBorder"></div>
            <img className="ThanksFooterImgLogo" src={footerLogo} alt="header" />
        </div>
    );
}

export default ThanksScreen;
