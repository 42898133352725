import './QuestionRadioGroup.css';
import React, { useState } from 'react';
import clsx from "clsx";
import Radio from '@material-ui/core/Radio';
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const QuestionRadioGroup = (props) => {

    const useStyles = makeStyles({
        icon: {
          borderRadius: "50%",
          width: 20,
          height: 20,
          boxShadow:
            "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
          backgroundColor: "#FFFFFF"
        },
        checkedIcon: {
          backgroundColor: props.color,
          backgroundImage:
            "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
          "&:before": {
            display: "block",
            width: 20,
            height: 20,
            backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
            content: '""'
          }
        }
      });

    const [selectedValue, setSelectedValue] = useState(-1)
    const handleChange = (event) => {
        const value = event.target.value
        setSelectedValue(value)
        props.onRadioSelected(value)
    }
    // Inspired by blueprintjs
    function StyledRadio(props) {
      const classes = useStyles();
    
      return (
        <Radio
          disableRipple
          color="default"
          checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
          icon={<span className={classes.icon} />}
          {...props}
        />
      );
    }

    return (
        <div className="QuestionRadioGroupContainer">
            <div className="QuestionRadioGroupTitle" style={{color: props.color}}>{props.question}<span className="QuestionRadioGroupTitle" style={{color: props.color, fontSize: '12px'}}>{props.subquestion}</span></div>
            <div className="QuestionRadioGroupRow">
                <div className="QuestionRadioGroupRowLeftRight">{props.prefix}</div>
                <div className="QuestionRadioGroupRowCenter">
                    {!props.compactMode && (
                        <FormControlLabel
                            className="QuestionRadioElement"
                            control={<StyledRadio
                                checked={selectedValue === "0"}
                                onChange={handleChange}
                                value="0"
                            />}
                            label="0"
                            labelPlacement="top"
                        />
                    )}
                    <FormControlLabel
                        className="QuestionRadioElement"
                        control={<StyledRadio
                            checked={selectedValue === "1"}
                            onChange={handleChange}
                            value="1"
                        />}
                        label="1"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        className="QuestionRadioElement"
                        control={<StyledRadio
                            checked={selectedValue === "2"}
                            onChange={handleChange}
                            value="2"
                        />}
                        label="2"
                        labelPlacement="top"
                    />
                    <FormControlLabel
                        className="QuestionRadioElement"
                        control={<StyledRadio
                            checked={selectedValue === "3"}
                            onChange={handleChange}
                            value="3"
                        />}
                        label="3"
                        labelPlacement="top"
                    />
                    {!props.compactMode && (
                        <>
                            <FormControlLabel
                                className="QuestionRadioElement"
                                control={<StyledRadio
                                    checked={selectedValue === "4"}
                                    onChange={handleChange}
                                    value="4"
                                />}
                                label="4"
                                labelPlacement="top"
                            />
                            <FormControlLabel
                                className="QuestionRadioElement"
                                control={<StyledRadio
                                    checked={selectedValue === "5"}
                                    onChange={handleChange}
                                    value="5"
                                />}
                                label="5"
                                labelPlacement="top"
                            />
                        </>
                    )}
                </div>
                <div className="QuestionRadioGroupRowLeftRight">{props.suffix}</div>
            </div>
        </div>
    );
}

export default QuestionRadioGroup;
