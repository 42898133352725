import header from './../img/header.jpeg';
import footerLogo from './../img/footerLogo.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Form.css';
import React, { useState, useEffect, useRef } from 'react';
import QuestionRadioGroup from './QuestionRadioGroup'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { firebaseApp, firestore } from '../helpers/firebase'
import { withStyles } from '@material-ui/core/styles';
import {
    KeyboardDatePicker,
    MuiPickersUtilsProvider,
  } from '@material-ui/pickers';
import moment from 'moment'
import MomentUtils from '@date-io/moment';

import 'moment/locale/fr'  // without this line it didn't work
moment.locale('fr')

const FormScreen = ({history}) => {
    const formRef = useRef(null)

    const emailRef = useRef("")
    const nameRef = useRef("")
    const telephoneRef = useRef("")
    const societeRef = useRef("")

    const [emailValid, setEmailValid] = useState(true)
    const [nameValid, setNameValid] = useState(true)
    const [telephoneValid, setTelephoneValid] = useState(true)

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [beginDate, setBeginDate] = React.useState(new Date());
    const [endDate, setEndDate] = React.useState(new Date());

    const [question1, setQuestion1] = useState(-1)
    const [question2, setQuestion2] = useState(-1)

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        if (query.get('email') !== null){
            emailRef.current.value = query.get('email')
        }
    }, [])

    const validateEmail = (email) => {
        const format = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return format.test(String(email).toLowerCase());
    }

    const validatePhone = (phone) => {
        const format = /^\d+$/;
        return format.test(String(phone).toLowerCase());
    }

    const sendButtonClicked = () => {

        const email = emailRef?.current?.value
        const name = nameRef?.current?.value
        const telephone = telephoneRef?.current?.value
        const societe = societeRef?.current?.value

        setEmailValid(email.length > 0 && validateEmail(email))
        setNameValid(name.length > 0)
        setTelephoneValid(telephone.length >= 8 && validatePhone(telephone))
    
        if (email.length === 0 || name.length === 0 || telephone.length === 0 || !validateEmail(email) || !validatePhone(telephone)){
            console.log('Missing infos')
            formRef.current.scrollIntoView() 
            return
        }

        setButtonDisabled(true)

        const inputData = {
            form: 'Impressive',
            email,
            name,
            telephone,
            societe,
            beginDate: firebaseApp.firestore.Timestamp.fromDate(moment(beginDate).toDate()),
            endDate: firebaseApp.firestore.Timestamp.fromDate(moment(endDate).toDate()),
            question1,
            question2,
            date: firebaseApp.firestore.Timestamp.now().toDate(),
        }

        firestore.collection("impressive").add(inputData)
        .then((docRef) => {
            setButtonDisabled(false)
            const params = {...inputData, realBeginDate: moment(beginDate).format('DD/MM/YYYY'), realEndDate: moment(endDate).format('DD/MM/YYYY')}
            history.push("/step2?id="+docRef.id, params)
            return
        })
        .catch((error) => {
            setButtonDisabled(false)
            alert('Une erreur est survenue, merci de réessayer plus')
        });
    }

    const CustomButton = withStyles({
        root: {
            textTransform: 'none',
            width: 150,
            height: 45,
            fontSize: 16,
            color: '#0078C1',
            border: '2px solid',
            borderRadius: 5,
            fontFamily: 'Neo Sans Pro Regular',
            backgroundColor: '#FFFFFF',
            borderColor: '#0078C1',
            '&:hover': {
                backgroundColor: '#0078C1',
                borderColor: '#0078C1',
                color: '#FFFFFF',
                boxShadow: 'none',
            },
        },
      })(Button);
    
    return (
        <div className="Container">
            <img className="HeaderImg" src={header} alt="header" />
            <div className="Title">Votre avis sur cet événement Impressive Tunisia member of Kyranis Travel</div>
            <div className="Description">Nous vous remercions pour la confiance accordée à Impressive Tunisia - Kyranis Travel , et nous espérons que vous avez eu autant de plaisir à y assister que nous à l'organiser. Nous aimerions connaître votre avis pour continuer à améliorer notre logistique et nos contenus.  Dans le cadre de notre démarche qualité liés aux normes ISO 9001 et ISO 20121 ,  nous vous adressons ce bref sondage pour nous faire part de vos impressions.</div>
            <div className="Form">
                <div className="Mandatory">*Obligatoire - Prière de répondre uniquement aux questions qui concernent votre évènement.</div>
                <div className="FormCell FormCellNoBorderBottom FormCellRoundTop">
                    
                        <div className="FormLeft" ref={formRef}>
                            <div className="FormLeftTitle">Logistique</div>
                            <div className="FormLeftDescription">Afin de mieux vous connaître, et pour revenir vers vous en cas de besoin.</div>
                        </div>
                    
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgTopLeft" src={imgTopLeft} alt="imgTopLeft" />
                        </div>
                        <div className="TextFieldForm">
                            <div style={{height: '30px'}}></div>
                            <div className="TextFieldTitle" >Adresse e-mail <span style={{color: '#FF0000'}}>*</span></div>
                            {emailValid ? 
                                <TextField className="TextField" variant="outlined" size="small" inputRef={emailRef}/> : 
                                <TextField error className="TextField" variant="outlined" size="small" inputRef={emailRef}/> 
                            }
                            {!emailValid ? <span className="ErrorMessage">Champ obligatoire ou format incorrect</span> : null}
                            <div className="TextFieldTitle">Votre nom & prénom <span style={{color: '#FF0000'}}>*</span></div>
                            {nameValid ? 
                                <TextField className="TextField" variant="outlined" size="small" inputRef={nameRef}/> :
                                <TextField error className="TextField" variant="outlined" size="small" inputRef={nameRef}/>
                            }
                            {!nameValid ? <span className="ErrorMessage">Champ obligatoire</span> : null }
                            <div className="TextFieldRow">
                                <div className="TextFieldRowColumn1">
                                    <div className="TextFieldTitle">Votre numéro de téléphone <span style={{color: '#FF0000'}}>*</span></div>
                                    {telephoneValid ? 
                                        <TextField className="TextField" variant="outlined" size="small" inputRef={telephoneRef}/> :
                                        <TextField error className="TextField" variant="outlined" size="small" inputRef={telephoneRef}/>
                                    }
                                    {!telephoneValid ? <span className="ErrorMessage">Champ obligatoire ou format incorrect</span> : null }
                                </div>
                                <div className="TextFieldRowColumn2">
                                    <div className="TextFieldTitle">Votre société</div>
                                    <TextField className="TextField" variant="outlined" size="small" inputRef={societeRef}/>
                                </div>
                            </div>
                            <div style={{height: '10px'}}></div>
                            <div className="TextFieldRow">
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                <div className="TextFieldRowColumn1">
                                    <div className="TextFieldTitle">Date de l'événement <span style={{color: '#FF0000'}}>*</span></div>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      variant="inline"
                                      inputVariant="outlined"
                                      className="TextField"
                                      label="Du"
                                      format="DD/MM/yyyy"
                                      value={beginDate}
                                      InputAdornmentProps={{ position: "end" }}
                                      onChange={(date) => setBeginDate(date)}
                                    />
                                </div>
                                <div className="TextFieldRowColumn2">
                                    <div className="TextFieldTitle" style={{height: '18px'}}></div>
                                    <KeyboardDatePicker
                                      disableToolbar
                                      variant="inline"
                                      inputVariant="outlined"
                                      className="TextField"
                                      label="Au"
                                      format="DD/MM/yyyy"
                                      value={endDate}
                                      InputAdornmentProps={{ position: "end" }}
                                      onChange={(date) => setEndDate(date)}
                                    />
                                </div>
                                </MuiPickersUtilsProvider>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className="FormCell FormCellRoundBottom FormCellTightTopBottom">
                    <div className="FormLeft"><span className="FormLeftTitle" style={{color: '#1E76BD'}}>Évènement</span></div>
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgBottomRight" src={imgBottomRight} alt="imgBottomRight" />
                        </div>
                        <div className="FormRightContent">
                            <QuestionRadioGroup question="Dans quelle mesure êtes vous satisfait du contenu de l'événement ?" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion1(value)}/>
                            <QuestionRadioGroup question="Comment jugez vous la mobilisation de Impressive Tunis - Kyranis Travel quant  au respect des principes du Développement Durable ?" subquestion="(en matière Sociale, Environnementale, Éthique et Économique)" prefix="Pas du tout engagé" suffix="Totalement engagée" color="#1E76BD" compactMode onRadioSelected={(value) => setQuestion2(value)}/>
                            <div style={{height: '30px'}}></div>
                        </div>
                    </div>
                </div>
                <div style={{height: '50px'}}></div>
                <CustomButton className="ButtonStyle" variant="outlined" disabled={buttonDisabled} color="primary" onClick={sendButtonClicked}>SUIVANT</CustomButton>
                <div style={{height: '50px'}}></div>
                <div className="DescriptionFooter">Ce formulaire a été créé dans Impressive Tunisia.<br/>Signaler un cas d'utilisation abusive</div>
            </div>
            <div className="FooterBorder"></div>
            <img className="FooterImgLogo" src={footerLogo} alt="header" />
        </div>
    );
}

export default FormScreen;
