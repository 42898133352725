import header from './../img/header.jpeg';
import footerLogo from './../img/footerLogo.png';
import imgTopLeft from './../img/img_top-left.png';
import imgBottomRight from './../img/img_bottom-right.png';
import './Form.css';
import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from "react-router";
import QuestionRadioGroup from './QuestionRadioGroup'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import { firebaseApp, firestore } from '../helpers/firebase'
import { useLocation } from "react-router-dom";
import { sendMail } from '../helpers/mail';

const Step2Screen = ({history}) => {
    const location = useLocation();

    const commentRef = useRef('')
    const suggestionRef = useRef('')
    const [documentId, setDocumentId] = useState(null)

    const [buttonDisabled, setButtonDisabled] = useState(false)

    const [question3, setQuestion3] = useState(-1)
    const [question4, setQuestion4] = useState(-1)
    const [question5, setQuestion5] = useState(-1)
    const [question6, setQuestion6] = useState(-1)
    const [question7, setQuestion7] = useState(-1)
    const [question8, setQuestion8] = useState(-1)
    const [question9, setQuestion9] = useState(-1)
    const [question10, setQuestion10] = useState(-1)
    const [question11, setQuestion11] = useState(-1)
    const [question12, setQuestion12] = useState(-1)
    const [question13, setQuestion13] = useState(-1)
    const [question14, setQuestion14] = useState(-1)
    const [question15, setQuestion15] = useState(-1)
    const [question16, setQuestion16] = useState(-1)
    const [question17, setQuestion17] = useState(-1)
    const [question18, setQuestion18] = useState(-1)
    const [question19, setQuestion19] = useState(-1)

    useEffect(() => {

        const query = new URLSearchParams(window.location.search);
        setDocumentId(query.get('id'))
        if (documentId === null){
            return <Redirect to="/" />;
        }
    }, [documentId])

    const sendButtonClicked = () => {

        setButtonDisabled(true)

        const comment = commentRef?.current?.value
        const suggestion = suggestionRef?.current?.value
        firestore.collection("impressive").doc(documentId).update({
            question3,
            question4,
            question5,
            question6,
            question7,
            question8,
            question9,
            question10,
            question11,
            question12,
            question13,
            question14,
            question15,
            question16,
            question17,
            question18,
            question19,
            comment,
            suggestion,
            date: firebaseApp.firestore.Timestamp.now().toDate(),
        })
        .then(() => {
            setButtonDisabled(false)

            const mailData = {
                ...location?.state,
                question3,
                question4,
                question5,
                question6,
                question7,
                question8,
                question9,
                question10,
                question11,
                question12,
                question13,
                question14,
                question15,
                question16,
                question17,
                question18,
                question19,
                comment,
                suggestion,
            }
            sendMail(mailData)
            history.push("/thanks")
        })
        .catch((error) => {
            setButtonDisabled(false)
            alert('Une erreur est survenue, merci de réessayer plus')
        });
    }

    const CssTextField = withStyles({
        root: {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                borderColor: '#77CFE4',
                },
                '&:hover fieldset': {
                borderColor: '#77CFE4',
                },
                '&.Mui-focused fieldset': {
                 borderColor: '#77CFE4',
                },
            },
        },
      })(TextField);

    const CustomButton = withStyles({
        root: {
            textTransform: 'none',
            width: 150,
            height: 45,
            fontSize: 16,
            color: '#0078C1',
            border: '2px solid',
            borderRadius: 5,
            fontFamily: 'Neo Sans Pro Regular',
            backgroundColor: '#FFFFFF',
            borderColor: '#0078C1',
            '&:hover': {
                backgroundColor: '#0078C1',
                borderColor: '#0078C1',
                color: '#FFFFFF',
                boxShadow: 'none',
            },
        },
      })(Button);

      const CustomBackButton = withStyles({
          root: {
              textTransform: 'none',
              width: 150,
              height: 45,
              fontSize: 16,
              color: '#77CFE4',
              border: '2px solid',
              borderRadius: 5,
              fontFamily: 'Neo Sans Pro Regular',
              backgroundColor: '#FFFFFF',
              borderColor: '#77CFE4',
              '&:hover': {
                  backgroundColor: '#77CFE4',
                  borderColor: '#77CFE4',
                  color: '#FFFFFF',
                  boxShadow: 'none',
              },
          },
        })(Button);

    return (
        <div className="Container">
            <img className="HeaderImg" src={header} alt="header" />
            <div className="Title">Votre avis sur cet événement Impressive Tunisia member of Kyranis Travel</div>
            <div className="Description">Nous vous remercions pour la confiance accordée à Impressive Tunisia - Kyranis Travel , et nous espérons que vous avez eu autant de plaisir à y assister que nous à l'organiser. Nous aimerions connaître votre avis pour continuer à améliorer notre logistique et nos contenus.  Dans le cadre de notre démarche qualité liés aux normes ISO 9001 et ISO 20121 ,  nous vous adressons ce bref sondage pour nous faire part de vos impressions.</div>
            <div className="Form">
                <div className="Mandatory">*Obligatoire - Prière de répondre uniquement aux questions qui concernent votre évènement.</div>
                <div className="FormCell FormCellNoBorderBottom FormCellRoundTop">
                    <div className="FormLeft">
                        <div className="FormLeftTitle" style={{color: '#1E76BD'}}>Logistique</div>
                        <div className="FormLeftDescription">Dans quelle mesure êtes vous satisfait de la logistique</div>
                    </div>
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgTopLeft" src={imgTopLeft} alt="imgTopLeft" />
                        </div>
                        <div style={{height: '30px'}}></div>
                        <QuestionRadioGroup question="Prise en charge et compréhension de votre demande" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion3(value)}/>
                        <QuestionRadioGroup question="Délais de réponse" prefix="Très long" suffix="Très court" color="#1E76BD" onRadioSelected={(value) => setQuestion4(value)}/>
                        <QuestionRadioGroup question="Choix du Lieu / Destination proposé" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion5(value)}/>
                        <QuestionRadioGroup question="Transfert / Transport" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion6(value)}/>
                        <QuestionRadioGroup question="Service aéroport et vol" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion7(value)}/>
                        <QuestionRadioGroup question="Hébergement" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion8(value)}/>
                        <QuestionRadioGroup question="Nourriture ( buffet / menu )" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion9(value)}/>
                        <QuestionRadioGroup question="Salles de réunion" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion10(value)}/>
                        <QuestionRadioGroup question="Matériels audio visuel" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion11(value)}/>
                        <QuestionRadioGroup question="Hygiène" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion12(value)}/>
                        <QuestionRadioGroup question="Choix des excursions" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion13(value)}/>
                        <QuestionRadioGroup question="Activités ludiques ( team building )" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion14(value)}/>
                        <QuestionRadioGroup question="Soirée ( animation artistique , plateau ..)" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion15(value)}/>
                        <QuestionRadioGroup question="Engagements de nos fournisseurs et parties prenantes" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion16(value)}/>
                        <QuestionRadioGroup question="Respect du cahier de charge" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion17(value)}/>
                        <QuestionRadioGroup question="Respect de l'environnement" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion18(value)}/>
                        <QuestionRadioGroup question="Réactivité du staff Impressive Tunisia - Kyranis Travel" prefix="Insatisfait" suffix="Très satisfait" color="#1E76BD" onRadioSelected={(value) => setQuestion19(value)}/>
                    </div>
                </div>
                <div className="FormCell FormCellRoundBottom FormCellTightTopBottom">
                        <div className="FormLeft">
                            <div className="FormLeftTitle" style={{color: '#77CFE4'}}>Recommandations et Suggestions</div>
                        </div>
                    <div className="FormRight PaddingLeftRight">
                        <div className="Overlay">
                            <img className="ImgBottomRight" src={imgBottomRight} alt="imgBottomRight" />
                        </div>
                        <div className="TextFieldForm">
                            <div className="TextFieldTitle" style={{color: '#77CFE4', marginBottom: '20px'}}>Vos recommandations pour des événements plus responsables</div>
                            <CssTextField className="TextFieldMultiRow" 
                                variant="outlined" 
                                multiline
                                rows={8}
                                placeholder="Votre réponse" 
                                inputRef={commentRef}/>
                        </div>
                        <div className="TextFieldForm">
                            <div className="TextFieldTitle" style={{color: '#77CFE4', marginBottom: '20px'}}>D'autres suggestions</div>
                            <CssTextField className="TextFieldMultiRow" 
                                variant="outlined" 
                                multiline
                                rows={8}
                                placeholder="Votre réponse" 
                                inputRef={suggestionRef}/>
                            <div style={{height: '50px'}}></div>
                        </div>
                    </div>
                </div>
                <div style={{height: '50px'}}></div>
                <div className="ButtonContainer">
                    <CustomBackButton className="ButtonStyle" variant="outlined" disabled={buttonDisabled} color="primary" onClick={() => window.history.back()} style={{ marginRight: '30px'}}>RETOUR</CustomBackButton>
                    <CustomButton className="ButtonStyle" variant="outlined" disabled={buttonDisabled} color="primary" onClick={(sendButtonClicked)}>ENVOYER</CustomButton>
                </div>
                <div style={{height: '50px'}}></div>
                <div className="DescriptionFooter">Ce formulaire a été créé dans Impressive Tunisia.<br/>Signaler un cas d'utilisation abusive</div>
            </div>
            <div className="FooterBorder"></div>
            <img className="FooterImgLogo" src={footerLogo} alt="header" />
        </div>
    );
}

export default Step2Screen;
